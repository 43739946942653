<template>
    <b-card no-body>
      <div v-show="!validarCardSemDados.verificadorVazio">
        <b-overlay :show="!loadingShow" rounded="lg" opacity="0.6">
          <b-card-header>
            <b-card-title class="mb-0 titulo">
              Respostas finalizadas
            </b-card-title>
          </b-card-header>
          <vue-apex-charts
            type="radialBar"
            :height="alturaResponsiva"
            class="mb-1"
            :options="graficoGoalOverviewRadialBar.chartOptions"
            :series="graficoGoalOverviewRadialBar.series"
          />
          <b-row class="text-center mx-0">
            <b-col xl="6" lg="6" md="6" class="border-top border-right py-1">
              <p class="text-muted mb-0 fonte-descricao">Finalizados</p>
              <p class="font-weight-bolder mb-0 fonte-descricao">
                {{ this.data.qtd_finalizado }}
              </p>
            </b-col>

            <b-col
              xl="6"
              lg="6"
              md="6"
              class="border-top border-right_responsivo py-1"
            >
              <p class="text-muted mb-0 fonte-descricao">Não Finalizado</p>
              <p class="font-weight-bolder mb-0 fonte-descricao">
                  {{ this.data.qtd_nao_finalizado }}
              </p>
            </b-col>
          </b-row>
        </b-overlay>
      </div>
      <div v-show="validarCardSemDados.verificadorVazio">
        <b-card-header class="card_header_responsivo">
          <b-card-title>Respostas finalizadas </b-card-title>
        </b-card-header>
        <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro">
        </card-sem-dados>
      </div>
    </b-card>
  </template>

  <script>
  import {
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BCardText,
    BOverlay,
    BCardTitle,
  } from "bootstrap-vue";
  import VueApexCharts from "vue-apexcharts";
  import CardSemDados from "@/views/pages/dashboard/CardSemDados.vue";

  const $strokeColor = "#ebe9f1";
  const $textHeadingColor = "#5E5873";

  export default {
    components: {
      VueApexCharts,
      BCard,
      BCardHeader,
      BRow,
      BCardText,
      BCol,
      BOverlay,
      BCardTitle,
      CardSemDados
    },
    props: {
      ApplicationData: {
        type: Object,
        default() {
          return {};
        },
        required: true,
      }
    },
    data() {
      return {
        loadingShow: false,
        pendentesTotal: 0,
        validarCardSemDados: {
          verificadorVazio: false,
          tipagemErro: 0,
        },
        alturaResponsiva: this.verificaAltura(),
        data: {},
        situacaoFase: "",
        graficoGoalOverviewRadialBar: {
          series: [],
          chartOptions: {},
        },
      };
    },
    beforeMount() {
        this.buscaRespostasFinalizados();
    },
    mounted() {
      window.addEventListener('resize', () => {
        this.alturaResponsiva = this.verificaAltura();
      });
    },
    methods: {
        buscaRespostasFinalizados(){
          this.loadingShow = false
          if(this.ApplicationData.dadosRespostasFinalizadas.length){
            this.data = this.ApplicationData.dadosRespostasFinalizadas[0]
            this.populacaoCard();
          }else{
            this.validarCardSemDados.verificadorVazio = true;
            this.validarCardSemDados.tipagemErro = 0;
          }
          this.loadingShow = true
        },

      calculoPorcentagemTooltip(valor, participantes) {
        if(participantes == 0 || valor == 0){
            return 0;
        }

        let porcentagem = ((valor / participantes) * 100).toFixed(0);
        if (porcentagem == 0) {
          porcentagem = this.toFixed((valor / participantes) * 100, 1);
          if (porcentagem == 0.0) {
            porcentagem = ((valor / participantes) * 100).toFixed(0);
          }
        }
        return porcentagem;
      },

      toFixed(num, fixed) {
        let re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
        return num.toString().match(re)[0];
      },

      chamadaGrafico() {
        return {
          chart: {
            height: this.alturaResponsiva,
            type: "radialBar",
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: ["#34B313"],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -135,
              endAngle: 135,
              hollow: {
                size: "77%",
              },
              track: {
                background: $strokeColor,
                strokeWidth: "50%",
              },
              dataLabels: {
                show: true,
                name: {
                  offsetY: 35,
                },
                value: {
                  offsetY: -10,
                  color: $textHeadingColor,
                  fontFamily: "Montserrat",
                  fontSize: "4rem",
                  fontWeight: "500",
                },
                total: {
                  show: true,
                  label: "Completo",
                  fontSize: "1.5rem",
                  fontFamily: undefined,
                  fontWeight: 0,
                  color: "#B9B9C3",
                },
              },
            },
          },

          fill: {
            type: "gradient",
            gradient: {
              type: "horizontal",
              gradientToColors: ["#2772BF"],
              inverseColors: false,
              stops: [0, 50],
            },
          },
          stroke: {
            lineCap: "round",
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
          tooltip: {
            enabled: true,
            theme: "dark",
            style: {
              fontSize: "14px",
            },
            custom: function () {
              return (
                '<div style="margin:20px;">' +
                "<div> 0% Recusas </div>" +
                "<div> 0% Respostas </div>" +
                "</div>"
              );
            },
          },
        };
      },
      verificaAltura(){
        if(window.innerWidth > 1999){
          return 500;
        }

        return 300;
      },
      populacaoCard() {


        let porcentagemFinalizado = this.calculoPorcentagemTooltip(
          this.data.qtd_resposta,
          this.data.qtd_finalizado
        );

        let porcentagemNaoFinalizado = this.calculoPorcentagemTooltip(
          this.data.qtd_resposta,
          this.data.qtd_nao_finalizado
        );

        this.graficoGoalOverviewRadialBar.chartOptions = this.chamadaGrafico();

        this.graficoGoalOverviewRadialBar.series = [
          (
            ((this.data.qtd_resposta) /
              this.data.qtd_finalizado) *
            100
          ).toFixed(0),
        ];

        this.graficoGoalOverviewRadialBar.chartOptions.tooltip.custom = [
          function () {
            return (
              '<div style="margin:20px;">' +
              "<div>" +
                porcentagemFinalizado +
              "% Finalizadas </div>" +
              "<div>" +
                porcentagemNaoFinalizado +
              "% Não Finalizadas </div>" +
              "</div>"
            );
          },
        ];
        this.loadingShow = true;
      },
    },
    watch: {
        ApplicationData: function () {
            this.buscaRespostasFinalizados();
            this.populacaoCard();

      },
    },
  };
  </script>
  <style scoped>
  .border-right_responsivo{
    border-right:1px solid #efefef;
  }
  .fonte-descricao{
    font-size: 1rem;
  }

  @media (min-width:2000px){
    .titulo{
      font-size: 2rem;
    }
  }
  @media (max-width: 1710px) {
    .col-xl-3{
      flex:0 0 50%;
      max-width:50%;
    }
    .border-right_responsivo{
      border-right:0px;
    }

  }


  </style>
