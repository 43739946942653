<template>
    <div>
      <div v-if="ApplicationData.dadosGraficoEscalaRespostaTotal.length > 0">
        <b-card-body>
          <div :class="{'mt-n2':true, 'mb-n3':true, 'ml-n2':true }">
            <b-row
              class="justify-content-center legenda-phq ml-lg-3"
              v-if="ApplicationData.nomeQuestionario == 'PHQ-9'"
            >
              <div class="mb-1 d-flex align-items-center ">
                <div class="legenda_item bola dias pontos"></div>
                <span class="legenda_item">Escala 0</span>
  
              </div>
              <div class="mb-1 d-flex align-items-center">
                <div class="legenda_item bola meses pontos"></div>
                <span class="legenda_item">Escala 1</span>
              </div>
              <div class="mb-1 d-flex align-items-center">
                <div class="legenda_item bola algumdia pontos"></div>
                <span class="legenda_item">Escala 2</span>
              </div>
              <div class="mb-1 d-flex align-items-center">
                <div class="legenda_item bola semmud pontos"></div>
                <span class="legenda_item">Escala 3</span>
              </div>
              <div class="mb-1 d-flex align-items-center">
                <div class="legenda_item bola naopre pontos"></div>
                <span class="legenda_item">Escala 4</span>
              </div>
            </b-row>
            <b-row
                class="justify-content-center legenda"
                v-if="ApplicationData.nomeQuestionario == 'DASS-21'"
              >
                <div class="mb-1 d-flex align-items-center ml-1">
                  <div class="legenda_item bola dias pontos"></div>
                  <span class="legenda_item">Sem Sintomas</span>
                </div>
                <div class="mb-1 d-flex align-items-center ml-1">
                  <div class="legenda_item bola meses pontos"></div>
                  <span class="legenda_item">Depressão</span>
                </div>
                <div class="mb-1 d-flex align-items-center ml-1">
                  <div class="legenda_item bola algumdia pontos"></div>
                  <span class="legenda_item">Ansiedade</span>
                </div>
                <div class="mb-1 d-flex align-items-center ml-1">
                  <div class="legenda_item bola semmud pontos"></div>
                  <span class="legenda_item">Estresse</span>
                </div>
              </b-row>
              <b-row>
                <b-col xl="6" lg="12" md="12">
                  <div>
                    <escala-respostas-por-genero-grafico-aplicacao
                      :ApplicationData="ApplicationData"
                      :rotulo="'Masculino'"
                      :telaCheia="telaCheia"
                    />
                  </div>
                </b-col>
                <b-col xl="6" lg="12" md="12">
                  <div>
                    <escala-respostas-por-genero-grafico-aplicacao
                      :ApplicationData="ApplicationData"
                      :rotulo="'Feminino'"
                      :telaCheia="telaCheia"
                    />
                  </div>
                </b-col>
              </b-row>
          </div>
        </b-card-body>
  
      </div>
      <div
          v-else
          class="height-custom d-flex align-items-center justify-content-center"
        >
          <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro"/>
      </div>
    </div>
  </template>
  
  <script>
  import {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardText,
    BCardBody,
    BCardTitle,
  } from "bootstrap-vue";
  import EscalaRespostasPorGeneroGraficoAplicacao from "@/views/components/custom/dashboard-aplicacao-cora-eventos/EscalaRespostasPorGeneroGraficoAplicacao.vue";
  import CardSemDados from "./CardSemDados.vue";
  import VuePerfectScrollbar from "vue-perfect-scrollbar";
  import vSelect from "vue-select";
  
  export default {
    components: {
      EscalaRespostasPorGeneroGraficoAplicacao,
      BRow,
      BCol,
      BCard,
      BCardHeader,
      BCardText,
      BCardBody,
      BCardTitle,
      vSelect,
      CardSemDados,
      VuePerfectScrollbar,
    },
    props: {
      ApplicationData: {
        type: Object,
        default() {
          return {};
        },
        required: true,
      },
      telaCheia: {
          type: Boolean,
          default: true,
          required: false,
        },
    },
    methods: {},
  
    data() {
      return {
        reRender: true,
        settings: {
          maxScrollbarLength: 70,
          wheelSpeed: 0.2,
          wheelPropagation: false,
        },
        validarCardSemDados: {
          verificadorVazio: true,
          tipagemErro: 0,
        },
        lista: [],
      };
    }
  };
  </script>
  
  <style scoped>
  
  .legenda{
      margin-left: 0px;
      margin-bottom: 10px;
  }
  .height-custom{
    height: 240px;
  }
  .legenda-phq{
        margin-left: 0px !important;
      }
  .legenda_item{
    display: inline-block;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 0.8rem;
    margin-left: 10px;
  }
  .pontos{
    min-width: 12px;
  }
  
  .bola{
    width: 12px;
    height: 12px;
    border-radius: 12px;
  
  }
  .dias {
  background: #36BA00;
  }
  .meses {
  background: #2772C0;
  }
  .algumdia {
    background: #002060;
  }
  
  .semmud {
    background: #7030A0;
  }
  
  .naopre {
    background: #AA228C;
  }
  .cor-escala-zero{
    background-color: #36BA00;
  }
  .cor-escala-um{
    background-color: #2772C0;
  }
  .cor-escala-dois{
    background-color: #002060;
  }
  .cor-escala-tres{
    background-color: #7030A0;
  }
  .cor-escala-quatro{
    background-color: #AA228C;
  }
  .texto-esquerda{
    text-align: left;
  }
  
  @media (min-width: 3000px) {
    .legenda_item{
      font-size: 2.3rem !important;
    }
  }
  
  @media (min-width: 2000px) {
    .titulo_grafico{
      font-size: 2.3rem;
    }
    .legenda_item{
      font-size: 1.3rem;
    }
  }
  
  @media(min-width: 1200px) and (max-width: 1500px) {
      .legenda_item{
        font-size: 0.7rem;
      }
  }
  
  @media(min-width: 992px) and (max-width: 1199px) {
      .ml-lg-3{
        margin-left: 1rem !important;
      }
  }
  
  @media (max-width: 1199px) {
    .bullet{
      margin-right: 8%;
    }
    .legenda_item{
        font-size: 0.8rem;
      }
      .legenda{
        margin-bottom: 3rem;
      }
  }
  @media (max-width: 991px) {
  
    .legenda_item{
      font-size: 0.8rem;
    }
    .card_header_responsivo{
      text-align: left;
      display: block !important;
    }
     
    .legenda{
        margin-left: 40px;
    }
    .legenda-phq{
        margin-left: 0px !important;
    }
  }
  @media (min-width: 768px) and (max-width: 992px){
      .legenda{
        margin-left: 0px !important;
        margin-bottom: 0 !important;
      }
      .legenda-phq{
        margin-left: 0px !important;
      }
    }
  @media (max-width: 767px) {
    .legenda_item{
      font-size: 0.8rem;
    }
  }
  
  @media (max-width: 575px) {
    .legenda{
        margin-left: 0px;
      }
      .legenda-phq{
        margin-left: 0px !important;
      }
  }
  
  </style>
  