<template>
    <b-card no-body>
      <div v-show="!validarCardSemDados.verificadorVazio" class="grafico">
        <b-card-header class="w">
          <b-card-title class="espacamento-grafico-titulo">
            Respostas por Faixa Etária
          </b-card-title>
        </b-card-header>
        <b-card-body class="mt-n2 mb-n3 ml-n2">
          <b-overlay :show="!loadingShow" rounded="lg" opacity="0.6">
            <b-col class="mb-1">
              <b-row
                class="alinhamento mt-2 mb-3"
                v-if="ApplicationData.nomeQuestionario == 'PHQ-9'"
              >
                <div class="d-flex align-items-center ml-1 espaco">
                  <div class="legenda bola escala0 ajuste-tamanho"></div>
                  <span class="legenda">Escala 0</span>
                </div>
                <div class="d-flex align-items-center ml-1 espaco">
                  <div class="legenda bola escala1 ajuste-tamanho"></div>
                  <span class="legenda">Escala 1</span>
                </div>
                <div class="d-flex align-items-center ml-1 espaco">
                  <div class="legenda bola escala2 ajuste-tamanho"></div>
                  <span class="legenda">Escala 2</span>
                </div>
                <div  class="d-flex align-items-center ml-1 espaco">
                  <div class="legenda bola escala3 ajuste-tamanho"></div>
                  <span class="legenda">Escala 3</span>
                </div>
                <div class="d-flex align-items-center ml-1 espaco">
                  <div class="legenda bola escala4 ajuste-tamanho"></div>
                  <span class="legenda">Escala 4</span>
                </div>
              </b-row>

              <b-row
                class="justify-content-center alinhamento-barra mt-2 mb-3  ml-lg-3"
                v-if="ApplicationData.nomeQuestionario == 'DASS-21'"
              >
                <div class="d-flex align-items-center ml-1">
                  <div class="legenda bola escala0"></div>
                  <span class="legenda">Sem sintomas</span>
                </div>
                <div class="d-flex align-items-center ml-1">
                  <div class="legenda bola escala1"></div>
                  <span class="legenda">Depressão</span>
                </div>
                <div class="d-flex align-items-center ml-1">
                  <div class="legenda bola escala2"></div>
                  <span class="legenda">Ansiedade</span>
                </div>
                <div class="d-flex align-items-center ml-1">
                  <div class="legenda bola escala3"></div>
                  <span class="legenda">Estresse</span>
                </div>
              </b-row>


              <div class="over">
                <div
                  class="espcamento-colunas pb-1 linha"
                  v-for="(item, item_id) in dadosParaOComponenteFuncionar"

                  :key="item_id"
                >

                  <b-row>
                    <b-col lg="3" md="3" class="tamanho-grafico">
                      <div :class="{'tituloClassificacaoes':true, 'espacamento-grafico':true,'texto-dass':ApplicationData.nomeQuestionario == 'DASS-21'}">{{ item.faixa_etaria }}</div>
                    </b-col>
                    <b-col lg="9" md="9">
                      <b-progress
                        :max="item.max"
                        class="rounded-0 largura"
                        :id="'tooltip-target-progress' + item_id"
                        :height="alturaResponsiva"
                        style="margin-top: 5px"
                      >
                        <b-progress-bar
                          v-for="(valor, valor_id) in item.valores
                            .slice()
                            "
                          :key="valor_id"
                          :value="valor.total_faixa_etaria"
                          :class="valor.classe"
                          :id="'tooltip-target-bar' + valor_id"
                        >
                        </b-progress-bar>
                        <b-tooltip
                          :target="'tooltip-target-progress' + item_id"
                          triggers="hover"
                          variant="light"
                          v-if="item.Faixa_etaria == 'Demais Classificacaoes'"
                        >
                        </b-tooltip>
                        <b-tooltip
                          :target="'tooltip-target-progress' + item_id"
                          triggers="hover"
                          variant="light"
                          v-else
                        >

                          <div class="text-left">
                            <strong class="fonte14">{{
                              item.Faixa_etaria
                            }}</strong>
                            <ul class="lista">
                              <li
                                v-for="(
                                  tooltipValor, tooltipValor_id
                                ) in item.valores.slice()"
                                :key="tooltipValor_id"
                                class="alturaLinha"
                              >
                                <span
                                  class="bullet"
                                  :class="tooltipValor.classe"
                                ></span>

                                <span>
                                    {{ tooltipValor.escala }}:<strong>
                                    {{ tooltipValor.total_faixa_etaria }}</strong
                                  ></span
                                >
                              </li>
                            </ul>
                          </div>
                        </b-tooltip>
                      </b-progress>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-overlay>
        </b-card-body>
      </div>
      <div v-show="validarCardSemDados.verificadorVazio">
        <b-card-header class="card_header_responsivo">
          <b-card-title>Respostas por Faixa Etária </b-card-title>
        </b-card-header>
        <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro">
        </card-sem-dados>
      </div>
    </b-card>
  </template>

  <script>
  import {
    BProgress,
    BCardHeader,
    BProgressBar,
    BCardText,
    BCard,
    BCardBody,
    BCardTitle,
    BRow,
    BCol,
    BTooltip,
    BOverlay,
  } from "bootstrap-vue";
  import VuePerfectScrollbar from "vue-perfect-scrollbar";
  import CardSemDados from "@/views/pages/dashboard/CardSemDados.vue";

  export default {
    components: {
      BCard,
      BProgress,
      BProgressBar,
      BCardText,
      BCardHeader,
      BCardBody,
      BCardTitle,
      BRow,
      BCol,
      BTooltip,
      VuePerfectScrollbar,
      BOverlay,
      CardSemDados,
    },
    props: {
        ApplicationData: {
        type: Object,
        default() {
          return {};
        },
        required: true,
      },
    },
    data() {
      return {
        loadingShow: false,
        dadosParaOComponenteFuncionar: [],
        validarCardSemDados: {
          verificadorVazio: false,
          tipagemErro: 0,
        },
        settings: {
          maxScrollbarLength: 70,
          wheelSpeed: 0.2,
          wheelPropagation: false,
        },
        alturaResponsiva: this.visibilidadeComprimento(),
      };
    },

    mounted() {
      this.buscaDadosCardclassificacaoRespostasClassificacao();
      window.addEventListener('resize', () => {
        this.alturaResponsiva = this.visibilidadeComprimento();
      });
    },
    methods: {
      buscaDadosCardclassificacaoRespostasClassificacao() {
        this.loadingShow = false;

        if(this.ApplicationData.dadosGraficoPorFaixaEtaria.length > 0){
          this.validarCardSemDados.verificadorVazio = false;
          let arrayDoBanco = this.ApplicationData.dadosGraficoPorFaixaEtaria;
          this.dadosParaOComponenteFuncionar = this.criarArrayDadosGrafico(arrayDoBanco);
          this.loadingShow = true;
        }else{
          this.validarCardSemDados.verificadorVazio = true;
          this.validarCardSemDados.tipagemErro = 1;
        }
      },
      criarArrayDadosGrafico(arrayDoBanco) {
        let arrayFormatacao = [];

        for (const element of arrayDoBanco) {
          if (element["faixa_etaria"] != null) {
            this.popularArray(arrayFormatacao, element);
          }
        }
        arrayFormatacao = this.criarPropMax(arrayFormatacao);
        return arrayFormatacao;
      },
      popularArray(arrayFormatacao, arrayBancoAtual) {
        if (
          !this.validadorExistenciaPrevia(
            arrayFormatacao,
            arrayBancoAtual["faixa_etaria"]
          )
        ) {
          if(this.ApplicationData.nomeQuestionario == 'PHQ-9'){
              arrayFormatacao.push({
                faixa_etaria: arrayBancoAtual["faixa_etaria"],
                valores: [
                  {
                    total_faixa_etaria: arrayBancoAtual["total_faixa_etaria"],

                    faixa_etaria: this.aliasclassificacao(
                      arrayBancoAtual["id_escala"]
                    ),
                    escala: arrayBancoAtual["escala"],
                    classe: this.aliasCor(arrayBancoAtual["id_escala"]),
                  },
                ],
              });
          }else if(this.ApplicationData.nomeQuestionario == 'DASS-21'){
            arrayFormatacao.push({
                faixa_etaria: arrayBancoAtual["faixa_etaria"],
                valores: [
                  {
                    total_faixa_etaria: arrayBancoAtual["total_faixa_etaria"],

                    faixa_etaria: this.aliasclassificacao(
                      arrayBancoAtual["id_doenca"]
                    ),
                    escala: arrayBancoAtual["doenca"],
                    classe: this.aliasCor(arrayBancoAtual["id_doenca"]),
                  },
                ],
              });
          }

        } else {
          for (const element of arrayFormatacao) {
            if (element["faixa_etaria"] == arrayBancoAtual["faixa_etaria"] && this.ApplicationData.nomeQuestionario == 'PHQ-9') {
              element["valores"].push({
                total_faixa_etaria: arrayBancoAtual["total_faixa_etaria"],
                faixa_etaria: arrayBancoAtual["faixa_etaria"],
                escala: arrayBancoAtual["escala"],
                classe: this.aliasCor(arrayBancoAtual["id_escala"]),
              });
            }else if(element["faixa_etaria"] == arrayBancoAtual["faixa_etaria"] && this.ApplicationData.nomeQuestionario == 'DASS-21'){
              element["valores"].push({
                total_faixa_etaria: arrayBancoAtual["total_faixa_etaria"],
                faixa_etaria: arrayBancoAtual["faixa_etaria"],
                escala: arrayBancoAtual["doenca"],
                classe: this.aliasCor(arrayBancoAtual["id_doenca"]),
              });
            }
          }
        }
      },
      validadorExistenciaPrevia(arrayDados, idEmpresaClassificacao) {
        return arrayDados.some((el) => el.faixa_etaria === idEmpresaClassificacao);
      },
      aliasclassificacao(classificacao) {
        if(this.ApplicationData.nomeQuestionario == 'PHQ-9'){
          switch (classificacao) {
            case 0:
                classificacao = "Sem sintomas";
                break;
            case 1:
                classificacao = "Sintomas mínimos";
                break;
            case 2:
                classificacao = "Sintomas leves";
                break;
            case 3:
                classificacao = "Sintomas moderados";
                break;
            case 4:
                classificacao = "Sintomas graves";
                break;
          }
          return classificacao;
        }else if(this.ApplicationData.nomeQuestionario == 'DASS-21'){
          switch (classificacao) {
            case 0:
                classificacao = "Sem sintomas";
                break;
            case 1:
                classificacao = "Depressão";
                break;
            case 2:
                classificacao = "Ansiedade";
                break;
            case 3:
                classificacao = "Estresse";
                break;
          }
          return classificacao;
        }



      },
      aliasCor(classe) {
        if(this.ApplicationData.nomeQuestionario == 'PHQ-9'){
          switch (classe) {
            case 0:
                classe = "escala0";
                break;
            case 1:
                classe = "escala1";
                break;
            case 2:
                classe = "escala2";
                break;
            case 3:
                classe = "escala3";
                break;
            case 4:
                classe = "escala4";
                break;

          }
          return classe;
        }else if(this.ApplicationData.nomeQuestionario == 'DASS-21'){
          switch (classe) {
            case 0:
                classe = "escala0";
                break;
            case 1:
                classe = "escala1";
                break;
            case 2:
                classe = "escala2";
                break;
            case 3:
                classe = "escala3";
                break;

          }
          return classe;
        }



      },
      criarPropMax(arrayDadosFormatados) {
        for (const element of arrayDadosFormatados) {
          let sum = 0;
          for (const aux of element["valores"]) {
            sum += aux["total_faixa_etaria"];
          }
          element.max = sum;
        }
        return arrayDadosFormatados;
      },
      visibilidadeComprimento(){
        if(window.innerWidth > 1999){
          return '2rem';
        }
        return '1rem';
      },
    },
    watch: {
        ApplicationData: function () {
            this.buscaDadosCardclassificacaoRespostasClassificacao();
      }
    }
  };
  </script>

  <style scoped>

  .lista{
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size:13px;
    width:100%;
    margin-right: 10px;
  }

  .alinhamento{
    justify-content: center;
  }
  .alturaLinha{
    height:20px;
  }

  .espacamento-grafico{
    margin-left: 10px !important;
    white-space:nowrap;
  }

  .espcamento-colunas{
    margin-bottom: 6px;
  }

  .legenda{
      display: inline-block;
      margin-left: 10px;

  }
  .ajuste-tamanho{
    min-width: 12px;
  }
  .over{
  overflow: hidden;
      max-height: 10%;
  }
  .grafico{
    padding-bottom: 1rem;
  }
  .bola{
    width: 12px;
    height: 12px;
    border-radius: 12px;
  }
  .escala0{
        background-color: #36BA00;
  }
  .escala1{
    background-color: #2772C0;
  }
 .escala2{
    background-color: #002060;
 }
 .escala3{
    background-color: #7030A0;
 }
 .escala4{
    background-color: #AA228C;
 }
  .texto-esquerda{
    text-align: left;
  }

  .bullet{
    margin-right: 3%;
  }

  @media (min-width: 1200px) and (max-width: 1500px) {
    .legenda{
     font-size: 10px;
    }
    .tituloClassificacaoes{
      font-size: 12px;
    }
    .espacamento-grafico{
      margin-left: 7px !important;
    }
    .espcamento-colunas{
      margin-bottom: 0.6rem;
      white-space: nowrap;
    }

  }
  @media (min-width: 2000px) and (max-width:2500px) {
    .tituloClassificacaoes{
      font-size: 20px !important;
    }
  }


  @media(min-width:1200px) and (max-width: 1212px){
      .linha{
        padding-bottom: 0 !important;
      }
      /* .pb-1{
        padding-bottom: 0 !important;
      } */
      .espcamento-colunas{
        margin-bottom: 23px;
        white-space: nowrap;
      }
  }

  @media (min-width:992px) and (max-width: 1041px){
    .texto-dass {
      font-size: 13px;
    }
    .espcamento-colunas{
      margin-bottom: 8px !important;
    }

  }
  @media (min-width: 2500px){
    .espcamento-colunas{
      margin-bottom: 7px !important;
    }
  }

  @media (min-width: 2000px) {
    .tituloClassificacaoes{
      font-size: 2rem;
    }
    .tamanho-grafico{
      max-width: 450px;
    }
    .largura{
      width: 115%;
    }
    .espacamento-grafico-titulo{
      font-size: 2.3rem;
    }
    .legenda{
      font-size: 2rem;
    }
    .espcamento-colunas{
      margin-bottom: 15px;
    }
  }


  @media (max-width: 1199px) {

    .bullet{
      margin-right: 3%;
    }
    .bola{
      min-width: 12px;
    }
    .espcamento-colunas{
      margin-bottom: 6px;
    }
    .espacamento-grafico{
      margin-left: 9px !important;
    }
  }
  @media (max-width: 991px) {
    .card_header_responsivo{
      text-align: left;
      display: block !important;
    }
    .espacamento-grafico{
      margin-left: 0px !important;
    }
    .espcamento-colunas{
      margin-bottom: 0px;
    }
    .espaco{
      margin-bottom: 1rem;
    }
  }

  @media (min-width: 768px) and (max-width: 1100px) {
    .bola{
      min-width: 12px;
    }
    .espacamento-grafico{
      margin-left: 5px !important;
    }
    .espcamento-colunas{
      margin-bottom: 6px;
    }
    .alinhamento-barra{
      justify-content: center !important;
    }

  }

  @media (min-width: 575px) and (max-width: 768px) {
    .bola{
      min-width: 12px;
    }
    .espacamento-grafico{
      margin-left: 0px !important;
    }
  }

  @media (max-width: 435px) {
    .alinhamento{
      justify-content: start;
      margin: 0 auto;
      margin-left: 35px;
    }
    .espacamento-grafico-titulo{
      margin-left: 0!important;
    }
  }

  </style>
