<template>
    <div>
      <b-card-header
        class="card_header_responsivo"
      >
        <b-card-title
          class="titulo_grafico"
        >
          <span
            v-b-tooltip.bottomright="{ customClass: 'custom-tooltip', title: tooltipText }"
          >
            {{ titulo }}
          </span>
        </b-card-title>
        <b-card-text
          v-if="subtitulo"
          class="subtitulo_grafico"
        >
          {{ subtitulo }}
        </b-card-text>
      </b-card-header>
    </div>
  </template>
  
  <script>
  import {
    BCardHeader,
    BCardTitle,
    BCardText,
    BButton,
    VBTooltip
  } from "bootstrap-vue";
  
  export default {
    components: {
      BCardHeader,
      BCardText,
      BCardTitle,
      BButton
    },
    directives: {
      'b-tooltip': VBTooltip,
    },
    props: {
      titulo: {
        type: String,
        required: true
      },
      subtitulo: {
        type: String,
        required: false
      },
      tooltipText: {
        type: String,
        required: false
      }
    }
  };
  </script>
  
  <style scoped>
  .card_header_responsivo{
    justify-content: space-between;
  }
  
  .titulo_grafico{
    padding-right: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
  }
  
  .subtitulo_grafico{
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
  }
  

  @media(min-width: 3000px){
    .titulo_grafico{
      font-size: 2rem;
    }
  }
  
  
  @media (max-width: 991px) {
    .card_header_responsivo{
      text-align: left;
      display: block;
    }
  }
  </style>
  